import { forwardRef } from 'react';
import { Typography } from '@mui/material';

import styled from 'utils/styled';

const Root = styled(Typography, {
  label: 'Text-root',
  ignore: ['lines'],
})(({ theme, lines }) => ({
  ...(Number.isFinite(lines) &&
    lines > 0 && {
      display: '-webkit-inline-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: lines,
    }),
}));

const Text = forwardRef((props, ref) => {
  const { component = 'span', variant = 'inherit', lines, ...rest } = props;

  return (
    <Root
      ref={ref}
      lines={lines}
      variant={variant}
      component={component}
      {...rest}
    />
  );
});

export default Text;
