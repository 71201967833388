import { forwardRef } from 'react';
import { ButtonBase as MuiButtonBase, alpha } from '@mui/material';

import styled from 'utils/styled';
import getPalette from 'styles/getPalette';

const sizes = {
  small: 32,
  medium: 40,
  large: 48,
};

const getNumber = (...args) => {
  let result;

  args.some((v) => {
    const isNumber = Number.isFinite(v);

    if (isNumber) {
      result = v;
    }
    return isNumber;
  });
  return result;
};

const Root = styled(MuiButtonBase, {
  label: 'ButtonBase-root',
  ignore: [
    'variant',
    'textVariant',
    'size',
    'height',
    'width',
    'fullWidth',
    'padding',
    'shadow',
  ],
})((props) => {
  const {
    size,
    theme,
    width,
    height,
    shadow,
    padding,
    variant,
    fullWidth,
    textVariant,
  } = props;

  const typography =
    theme.typography[textVariant] || theme.typography.subtitle2;

  const h = getNumber(height, sizes[size], size, sizes.medium);
  const w = fullWidth ? '100%' : width || 'auto';
  const p = Array.isArray(padding) ? padding : [padding];

  const variantStyles = (() => {
    if (variant === 'contained') {
      return {
        color: theme.vars.palette.grey[0],
        backgroundColor: theme.vars.palette.primary.dark,

        '&:hover, &:focus-visible': {
          color: theme.vars.palette.primary.dark,
          backgroundColor: theme.vars.palette.grey[0],
          boxShadow: shadow ? theme.vars.shadow[100] : undefined,
        },
      };
    }
    if (variant === 'outlined') {
      return {
        color: theme.vars.palette.grey[800],
        backgroundColor: theme.vars.palette.grey[0],
        boxShadow: shadow ? theme.vars.shadow[100] : undefined,

        '&:hover, &:focus-visible': {
          color: theme.vars.palette.primary.dark,
          boxShadow: 'none',
          border: `1px solid ${theme.vars.palette.primary.dark}`,
        },
      };
    }
    if (variant === 'text') {
      return {
        backgroundColor: alpha(getPalette(theme).grey[900], 0),

        '&:hover, &:focus-visible': {
          backgroundColor: alpha(getPalette(theme).grey[900], 0.05),
        },
      };
    }
  })();

  return {
    border: '1px solid transparent',
    borderRadius: theme.spacing(1),
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shorter,
    }),
    ...typography,
    height: h,
    width: w,
    padding: theme.spacing(...p),
    ...variantStyles,
    lineHeight: 1,
  };
});

const ButtonBase = forwardRef((props, ref) => {
  const {
    loading,
    children,
    fullWidth,
    padding = 1,
    size = 'medium',
    variant = 'contained',
    textVariant = 'subtitle2',
    focusRipple = true,
    shadow = 100,
    ...rest
  } = props;

  return (
    <Root
      ref={ref}
      size={size}
      padding={padding}
      variant={variant}
      fullWidth={fullWidth}
      shadow={shadow}
      textVariant={textVariant}
      focusRipple={focusRipple}
      {...rest}
    >
      {children}
    </Root>
  );
});

export default ButtonBase;
