import { forwardRef } from 'react';
import cx from 'clsx';
import { Switch as MuiSwitch, switchClasses } from '@mui/material';

import styled from 'utils/styled';

import styles from './switch.module.scss';

const sizes = {
  xxsmall: 16,
  xsmall: 24,
  small: 32,
  medium: 40,
  large: 48,
};

const widthSizes = {
  xxsmall: 32,
  xsmall: 48,
  small: 56,
  medium: 64,
  large: 72,
};

const StyledSwitch = styled(MuiSwitch, {
  label: 'Switch-root',
  ignore: ['size'],
})(({ theme, size }) => {
  const height = sizes[size] || size || sizes.xsmall;
  const width = widthSizes[size] || widthSizes.xsmall;
  const p = (height - height / 1.2) / 2;

  return {
    width,
    height,
    padding: 0,
    borderRadius: height / 2,
    outline: '2px solid transparent',

    [`& > .${switchClasses.switchBase}`]: {
      padding: p,

      [`& > .${switchClasses.thumb}`]: {
        height: height / 1.2,
        width: height / 1.2,
        boxShadow: '0px 2px 4px 0px rgba(29, 31, 33, 0.20)',
        border: '1px solid transparent',
        transition: theme.transitions.create('all', {
          duration: theme.transitions.duration.shorter,
        }),
      },
      [`&.${switchClasses.checked}`]: {
        transform: `translateX(${width - height}px)`,
      },

      '&:hover': {
        backgroundColor: 'transparent',

        [`& > .${switchClasses.thumb}`]: {
          border: `1px solid ${theme.vars.palette.primary.dark}`,
        },
      },
    },
    [`& > .${switchClasses.track}`]: {
      opacity: 1,
      backgroundColor: theme.vars.palette.colors.control_resting,
    },

    [`&:has(.${switchClasses.switchBase}.Mui-focusVisible)`]: {
      outline: `2px solid ${theme.vars.palette.primary.dark}`,
    },

    [`&:has(.${switchClasses.switchBase}.${switchClasses.checked}), &:has(.${switchClasses.switchBase}.Mui-focusVisible)`]:
      {
        [`& > .${switchClasses.switchBase}`]: {
          [`& > .${switchClasses.thumb}`]: {
            backgroundColor: theme.vars.palette.grey[0],
          },

          '&:hover, &.Mui-focusVisible': {
            [`& > .${switchClasses.thumb}`]: {
              border: '1px solid transparent',
              backgroundColor: theme.vars.palette.primary.dark,
            },
          },
        },
        [`& > .${switchClasses.track}`]: {
          opacity: 1,
          backgroundColor: theme.vars.palette.primary.dark,
        },
        [`&:has(.${switchClasses.switchBase}:hover), &:has(.${switchClasses.switchBase}.Mui-focusVisible)`]:
          {
            [`& > .${switchClasses.track}`]: {
              backgroundColor: theme.vars.palette.colors.control_resting,
            },
          },
      },
  };
});

const Switch = forwardRef((props, ref) => {
  const {
    label,
    icon,
    size = 'xsmall',
    fullWidth,
    className,
    labelPosition = 'end',
    component,
    ...rest
  } = props;

  const Root = component || (label ? 'label' : 'div');

  return (
    <Root
      ref={ref}
      className={cx(styles.root, fullWidth && styles.fullWidth, className)}
    >
      {label && labelPosition === 'start' && label}

      <StyledSwitch size={size} {...rest} />

      {label && labelPosition === 'end' && label}
    </Root>
  );
});

export default Switch;
