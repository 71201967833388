const fontFamily = [
  'Roboto',
  '"System UI"',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const baseFontSize = 14;

const fontSize = 14;

const coef = fontSize / baseFontSize;

const htmlFontSize = 16;

const pxToRem = (size) => `${(size / htmlFontSize) * coef}rem`;

export const textBase = {
  fontFamily,
  fontSize,
};

const createFontVariant = (variant) => {
  const result = { ...textBase, ...variant };

  return {
    fontSize: pxToRem(result.fontSize),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.5,
    ...result,
  };
};

const title1 = {
  fontSize: 32,
  fontWeight: 400,
  lineHeight: '40px', // 1.25
  letterSpacing: '0.08px', // 0.002em
};
const title2 = {
  fontSize: 28,
  fontWeight: 500,
  lineHeight: '36px', // 1.28571
  letterSpacing: '0.07px', // 0.0025em
};
const title3 = {
  fontSize: 24,
  fontWeight: 500,
  lineHeight: '32px', // 1.33333
};

const subtitle1 = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '24px', // 1.5
  letterSpacing: '0.16px', // 0.00666em
};
const subtitle2 = {
  fontSize: 16,
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.16px',
};

const body1 = {
  fontSize: 14,
  fontWeight: 400,
  lineHeight: '20px',
  letterSpacing: '0.035px',
};
const body2 = {
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.035px',
};

const caption = {
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: '0.24px',
  textTransform: 'uppercase',
};
const caption1 = { ...caption };
const caption2 = {
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: '0.24px',
  textTransform: 'uppercase',
};

const hint = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.06px',
};
const label1 = {
  fontSize: 12,
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.12px',
};
const label2 = {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.12px',
};

const variants = {
  title1,
  title2,
  title3,
  subtitle1,
  subtitle2,
  body1,
  body2,
  caption,
  caption1,
  caption2,
  hint,
  label1,
  label2,
};

export const textVariants = Object.entries(variants).reduce(
  (res, [name, font]) => {
    return {
      ...res,
      [name]: createFontVariant(font),
    };
  },
  {}
);
