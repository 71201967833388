import { styled as muiStyled } from '@mui/material/styles';

import withProps from 'utils/withProps';

const styled = function (component, options = {}) {
  const { props, ignore, shouldForwardProp, ...rest } = options;

  const Component = props ? withProps(component, props) : component;

  const validateProp =
    !Array.isArray(ignore) && !shouldForwardProp
      ? null
      : (prop) => {
          const mustBeIgnored = Array.isArray(ignore) && ignore.includes(prop);
          const valid =
            typeof shouldForwardProp !== 'function' || shouldForwardProp(prop);
          return !mustBeIgnored && valid;
        };
  return muiStyled(Component, {
    ...rest,
    shouldForwardProp: validateProp,
  });
};
styled.prototype = muiStyled.prototype;

export default styled;
