import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { unstable_batchedUpdates } from 'react-dom';
import { SvgIcon } from '@mui/material';

import withProps from 'utils/withProps';
import { getArray } from 'utils/helpers';
import styled from 'utils/styled';
import SvgLoader from 'components/SvgLoader';

const Root = styled(SvgIcon, {
  label: 'IconBase-root',
  ignore: [
    'color',
    'colors',
    'rotate',
    'flipX',
    'flipY',
    'customFontSize',
    'nativeColors',
    'customWidth',
    'customHeight',
  ],
})((props) => {
  const {
    theme,
    rotate,
    flipX,
    flipY,
    color,
    colors,
    customWidth,
    customHeight,
    nativeColors,
    customFontSize,
  } = props;

  const transform = (() => {
    return [
      `rotate(${rotate || 0}deg)`,
      flipX && 'scaleX(-1)',
      flipY && 'scaleY(-1)',
    ]
      .filter(Boolean)
      .join(' ');
  })();

  const fontSize = useMemo(() => {
    if (Number.isFinite(customFontSize)) {
      return customFontSize;
    }
    return undefined;
  }, [customFontSize]);

  const c = theme.sx({ color }).color;

  const cs = getArray(nativeColors)
    .map((clr, colorIndex) => {
      const commonColor = c !== 'inherit' ? c : undefined;
      return commonColor || colors?.[colorIndex] || clr;
    })
    .map((clr) => {
      return theme.sx({ color: clr }).color || color;
    });

  return {
    width: customWidth,
    height: customHeight,
    color: c,
    fontSize,
    transform,
    ...cs.reduce((res, colorValue, colorIndex) => {
      return {
        ...res,
        [`& [data-color-index="${colorIndex}"]`]: {
          fill: colorValue,
        },
      };
    }, {}),
  };
});

const IconBase = forwardRef((props, ref) => {
  const {
    src,
    rotate = 0,
    flipX,
    flipY,
    debug,
    colors,
    width: customWidth = 'auto',
    height: customHeight = 'auto',
    className,
    color = 'inherit',
    fontSize = 'medium',
    viewBox: initialViewBox = '0 0 24 24',
    ...rest
  } = props;

  const mountRef = useRef(false);
  const [viewBox, setViewBox] = useState(initialViewBox);
  const [nativeColors, setNativeColors] = useState();

  const [w, h] = useMemo(() => {
    return viewBox.split(' ').slice(2).map(Number.parseFloat);
  }, [viewBox]);

  if (debug) {
    console.log(w, h);
  }

  const nativeFontSize = useMemo(() => {
    if (['small', 'medium', 'large', 'inherit', undefined].includes(fontSize)) {
      return fontSize;
    }
    return 'inherit';
  }, [fontSize]);

  const handleSourceLoading = useCallback(
    (source) => {
      const [, currentViewBox] = getArray(source.match(/viewBox="(.*?)"/im));
      const colorsDict = [];

      const template = document.createElement('template');
      template.innerHTML = source.trim();
      const element = template.content.firstChild;
      const coloredElements = Array.from(element.querySelectorAll('[fill]'));

      coloredElements.forEach((ce) => {
        const elementColor = ce.getAttribute('fill');

        if (!colorsDict.includes(elementColor)) {
          colorsDict.push(elementColor);
        }
        const colorIndex = colorsDict.indexOf(elementColor);
        ce.setAttribute('data-color-index', colorIndex);
      });
      setTimeout(() => {
        unstable_batchedUpdates(() => {
          if (!mountRef.current) {
            return;
          }
          if (debug) {
            console.log(currentViewBox);
          }
          setViewBox(currentViewBox);
          setNativeColors(colorsDict);
        });
      }, 4);

      return element.outerHTML;
    },
    [debug]
  );

  const InlineIcon = useMemo(() => {
    return withProps(SvgLoader, {
      src,
      preProcessor: handleSourceLoading,
    });
  }, [src, handleSourceLoading]);

  useEffect(() => {
    mountRef.current = true;

    return () => {
      mountRef.current = false;
    };
  }, []);

  return (
    <Root
      ref={ref}
      color={color}
      colors={colors}
      rotate={rotate}
      flipX={flipX}
      flipY={flipY}
      width={w}
      height={h}
      customWidth={customWidth}
      customHeight={customHeight}
      className={className}
      nativeColors={nativeColors}
      viewBox={viewBox}
      fontSize={nativeFontSize}
      customFontSize={fontSize}
      component={InlineIcon}
      {...rest}
    />
  );
});

export default IconBase;
