import { Suspense, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, Route, Routes, useMatch } from 'react-router-dom';

import { getArray, getFunc } from 'utils/helpers';

import routesTree, { routes } from './routes';

const CustomOutlet = (props) => {
  const { element, redirect, fullPath } = props;

  const match = useMatch({
    path: fullPath || '',
    exact: true,
  });
  if (typeof redirect === 'function' && !!match) {
    return <Navigate to={redirect()} replace />;
  }
  if (element) {
    return (
      <ErrorBoundary fallback={null}>
        <Suspense fallback={null}>{element}</Suspense>
      </ErrorBoundary>
    );
  }
  return <Outlet />;
};

const getCustomRoutes = (customRoutes) => {
  const result = getArray(customRoutes).map((route, i) => {
    const { index, path: buildPath, id } = route;

    const path = getFunc(buildPath)();
    const fullPath = id ? routes[id].path() : undefined;
    const nativeChildren = getCustomRoutes(route.children);

    return (
      <Route
        key={i}
        path={path}
        index={index}
        element={<CustomOutlet {...route} fullPath={fullPath} />}
      >
        {nativeChildren}
      </Route>
    );
  });
  if (result.length === 0) {
    return null;
  }
  return result;
};

const useCustomRoutes = (customRoutes) => {
  return useMemo(() => {
    return getCustomRoutes(customRoutes);
  }, [customRoutes]);
};

const ProjectRoutes = (props) => {
  const { children } = props;
  const content = useCustomRoutes(routesTree);

  return (
    <>
      <Routes>{content}</Routes>
      {children}
    </>
  );
};

export default ProjectRoutes;
