import { forwardRef, useMemo } from 'react';
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  tabClasses,
  tabsClasses,
} from '@mui/material';

import styled from 'utils/styled';
import withProps from 'utils/withProps';
import TabsBase from 'components/TabsBase';

const sizes = {
  small: 32,
  medium: 40,
  large: 42,
};

const StyledTab = styled(MuiTab, {
  label: 'HeaderTabs-tab',
})(({ theme }) => ({
  padding: theme.spacing(0.5, 1.5),
  ...theme.typography.body1,
  textTransform: 'none',
  color: theme.vars.palette.text.secondary,
  minHeight: 'auto',
  borderRadius: theme.spacing(1),
  minWidth: 30,

  [`&.${tabClasses.selected}, &:hover`]: {
    color: theme.vars.palette.primary.dark,
  },
}));

const StyledTabs = styled(MuiTabs, {
  label: 'HeaderTabs-root',
  ignore: ['size', 'bgcolor', 'gap', 'shadow', 'fullWidth'],
})(({ theme, size, bgcolor, shadow, fullWidth, gap }) => ({
  minHeight: sizes[size] || size,
  padding: theme.spacing(0.5),
  backgroundColor: theme.sx({ color: bgcolor }).color,
  borderRadius: theme.spacing(1),

  [`& > .${tabsClasses.scroller}`]: {
    overflow: 'visible !important',

    [`& > .${tabsClasses.indicator}`]: {
      height: '100%',
      borderRadius: theme.spacing(1),
      backgroundColor: theme.vars.palette.grey[0],
      boxShadow: theme.vars.shadow[shadow] || undefined,
      border: `1px solid ${shadow ? theme.vars.palette.grey[100] : 'transparent'}`,
    },
    [`& > .${tabsClasses.flexContainer}`]: {
      height: '100%',
      position: 'relative',
      zIndex: 10,
      gap: theme.spacing(gap),

      ...(fullWidth && {
        [`& > ${StyledTab}`]: {
          flexGrow: 1,
        },
      }),
    },
  },
}));

const Tabs = forwardRef((props, ref) => {
  const {
    shadow,
    fullWidth = false,
    bgcolor = 'colors.input_resting',
    size = 'small',
    gap = 0.5,
    ...rest
  } = props;

  const TabsComponent = useMemo(() => {
    return withProps(StyledTabs, {
      gap,
      size,
      bgcolor,
      shadow,
      fullWidth,
    });
  }, [bgcolor, size, shadow, fullWidth, gap]);

  return (
    <TabsBase
      ref={ref}
      TabComponent={StyledTab}
      TabsComponent={TabsComponent}
      {...rest}
    />
  );
});

export default Tabs;
