import React, { forwardRef } from 'react';

/**
 * Predefine component props
 * @param {React.Component} Component
 * @param {object} defaultProps
 * @returns <React.Component>
 */
const withProps = (Component, defaultProps) => {
  const Wrapper = (props, ref) => {
    return <Component ref={ref} {...defaultProps} {...props} />;
  };
  return forwardRef(Wrapper);
};

export default withProps;
