import { forwardRef } from 'react';

import styled from 'utils/styled';
import ButtonBase from 'components/ButtonBase';

const IconWrapper = styled('span', {
  label: 'Button-icon',
})({
  position: 'relative',
  display: 'inline-flex',
});

const Root = styled(ButtonBase, {
  label: 'Button-root',
  ignore: ['gap'],
})(({ theme, gap }) => ({
  display: 'flex',
  gap: theme.spacing(gap),
}));

const Label = styled('span', {
  label: 'Button-label',
})(({ theme }) => ({
  lineHeight: 1,
  display: 'inline-flex',
  alignItems: 'center',
  marginTop: theme.spacing(0.25),
}));

const Button = forwardRef((props, ref) => {
  const { children, iconStart, iconEnd, gap = 0.5, ...rest } = props;

  return (
    <Root ref={ref} gap={gap} {...rest}>
      {iconStart && <IconWrapper>{iconStart}</IconWrapper>}

      <Label>{children}</Label>

      {iconEnd && <IconWrapper>{iconEnd}</IconWrapper>}
    </Root>
  );
});

export default Button;
