import { cloneElement, forwardRef, useEffect, useMemo, useState } from 'react';

import convert from 'react-from-dom';

const cache = {};
const requests = {};

const makeRequest = async (src) => {
  const response = await fetch(src, {
    cache: 'force-cache',
  });
  const content = await response.text();
  cache[src] = content;
  requests[src] = null;
  return content;
};

const getRequest = (src) => {
  if (!src) {
    return null;
  }
  if (requests[src]) {
    return requests[src];
  }
  const request = makeRequest(src);
  requests[src] = request;
  return request;
};

const getContent = async (src) => {
  if (cache[src]) {
    return cache[src];
  }
  return getRequest(src);
};

const SvgLoader = forwardRef((props, ref) => {
  const { src, preProcessor, viewBox, ...rest } = props;

  const [content, setContent] = useState(cache[src] || null);

  const element = useMemo(() => {
    if (!content) {
      if (viewBox) {
        // Placeholder
        return <svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" />;
      }
      return null;
    }
    if (typeof preProcessor === 'function') {
      return convert(preProcessor(content));
    }
    return convert(content);
  }, [content, preProcessor, viewBox]);

  const getter = useMemo(() => {
    return getContent(src);
  }, [src]);

  useEffect(() => {
    let mounted = true;

    getter.then((responseContent) => {
      if (mounted) {
        setContent(responseContent);
      }
    });
    return () => {
      mounted = false;
    };
  }, [getter]);

  if (element) {
    return cloneElement(element, {
      ref,
      ...element.props,
      ...rest,
      children: element.props.children,
    });
  }
  return element;
});

export default SvgLoader;
