import { lazy } from 'react';

import { getArray, getFunc } from 'utils/helpers';
import { isDevelopment } from 'constants/config';
import MainLayout from 'layouts/MainLayout';

const Alerts = lazy(() => import('pages/Alerts'));
const Reports = lazy(() => import('pages/Reports'));
const Map = lazy(() => import('pages/Map'));
const Timeline = lazy(() => import('pages/Timeline'));

const routesTree = [
  {
    id: 'root',
    path: () => '/',
    redirect: () => routes.map.path(),
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            id: 'timeline',
            path: () => 'timeline',
            element: <Timeline />,
          },
          {
            id: 'alerts',
            path: () => 'alerts',
            element: <Alerts />,
          },
          {
            id: 'map',
            path: () => 'map',
            element: <Map />,
          },
          {
            id: 'reports',
            path: () => 'reports',
            element: <Reports />,
          },
        ],
      },
    ],
  },
];

const buildRoutes = (routesTree, path) => {
  const result = getArray(routesTree).reduce((result, route) => {
    const levelPath = (params) => {
      const currentLevelPath = getFunc(route.path)(params) || '';
      const previousLevelPath = getFunc(path)(params) || '';
      const needDelimiter = !(
        previousLevelPath.endsWith('/') || currentLevelPath.endsWith('/')
      );
      const delimiter = needDelimiter ? '/' : '';
      return `${previousLevelPath}${delimiter}${currentLevelPath}`;
    };
    const innerRoutes = buildRoutes(route.children, levelPath);
    const currentRoute = {};

    if (route.id) {
      currentRoute[route.id] = { path: levelPath };
    }
    return { ...result, ...innerRoutes, ...currentRoute };
  }, {});

  return result;
};

export const routes = buildRoutes(routesTree);

export const routesList = Object.entries(routes).reduce((res, [key, build]) => {
  return {
    ...res,
    [key]: build.path(),
  };
}, {});

if (isDevelopment) {
  window.bw = {
    ...window.bw,
    routesList,
  };
}

export default routesTree;
