import { forwardRef } from 'react';
import { Badge as MuiBadge, badgeClasses } from '@mui/material';

import styled from 'utils/styled';

const Root = styled(MuiBadge, {
  label: 'Badge-root',
})({
  [`& > .${badgeClasses.badge}`]: {
    top: '15%',
    right: '30%',
  },
});

const Badge = forwardRef((props, ref) => {
  const {
    show,
    invlisible,
    color = 'error',
    variant = 'dot',
    overlap = 'circular',
    ...rest
  } = props;

  return (
    <Root
      ref={ref}
      color={color}
      variant={variant}
      overlap={overlap}
      invisible={typeof show === 'boolean' ? !show : show}
      {...rest}
    />
  );
});

export default Badge;
