import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cx from 'clsx';
import { Box, alpha } from '@mui/material';

import getPalette from 'styles/getPalette';
import styled from 'utils/styled';
import styles from './ref.module.scss';

const Root = styled(Box, {
  label: 'Ref-root',
  ignore: ['nostyles', 'cursor'],
})(({ theme, nostyles, cursor }) => ({
  cursor,
  textDecoration: 'none',
  color: 'inherit',

  ...(!nostyles && {
    transition: theme.transitions.create(),
    color: theme.vars.palette.primary.main,
    background: `
      linear-gradient(
        to right,
        ${alpha(getPalette(theme).primary.light, 0.5)},
        ${alpha(getPalette(theme).primary.light, 0.5)}
      ),
      linear-gradient(
        to right,
        ${theme.vars.palette.primary.dark},
        ${theme.vars.palette.primary.dark}
      )
    `,
    backgroundPosition: '100% 100%, 0 100%',
    backgroundSize: '100% 1px, 0px 1px',
    backgroundRepeat: 'no-repeat',

    '&:hover, &:focus-visible': {
      color: theme.vars.palette.primary.dark,
      backgroundSize: '0px 1px, 100% 1px',
      outline: 'none',
    },
  }),
}));

const Ref = forwardRef((props, ref) => {
  const {
    to,
    blank,
    absolute,
    children,
    className,
    tabIndex = 0,
    nostyles,
    cursor = 'pointer',
    component: customComponent,
    ...rest
  } = props;

  const component = useMemo(() => {
    if (customComponent) {
      return customComponent;
    }
    if (absolute) {
      return 'a';
    }
    if (to) {
      return Link;
    }
    return 'span';
  }, [absolute, customComponent, to]);

  const defaults = useMemo(() => {
    const result = {};

    if (component === 'a') {
      if (to) {
        result.href = to;
      }
      if (blank) {
        result.target = '_blank';
        result.rel = 'noreferrer noopener';
      }
    }
    if (component === Link) {
      if (to) {
        result.to = to;
      }
      if (blank) {
        result.target = '_blank';
        result.rel = 'noreferrer noopener';
      }
    }
    return result;
  }, [blank, component, to]);

  return (
    <Root
      ref={ref}
      cursor={cursor}
      nostyles={nostyles}
      tabIndex={tabIndex}
      component={component}
      className={cx(styles.root, className)}
      {...defaults}
      {...rest}
    >
      {children}
    </Root>
  );
});

export default Ref;
