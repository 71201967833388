import { Outlet } from 'react-router-dom';

import HeaderLayout from 'layouts/HeaderLayout';

import styles from './mainLayout.module.scss';

const MainLayout = (props) => {
  const { children } = props;

  return (
    <div className={styles.root}>
      <HeaderLayout />

      <main className={styles.main}>{children || <Outlet />}</main>
    </div>
  );
};

export default MainLayout;
