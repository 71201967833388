const defFunc = () => {};
const defArray = Object.freeze([]);

export const getString = (s) => {
  if (typeof s === 'string') {
    return s;
  }
  if (Number.isFinite(s)) {
    return String(s);
  }
  return '';
};

export const getArray = (v) => {
  return Array.isArray(v) ? v : defArray;
};

export const getFunc = (f) => {
  return typeof f === 'function' ? f : defFunc;
};

export const snakeCaseToCamelCase = (name, cap) => {
  return getString(name)
    .toLowerCase()
    .split('_')
    .map((part, i) => {
      const first = getString(part[0]);
      const rest = getString(part.slice(1)).toLowerCase();

      return [
        i > 0 || cap ? first.toUpperCase() : first.toLowerCase(),
        rest,
      ].join('');
    })
    .join('');
};

export const getMonthStart = (d) => {
  const v = new Date(d);
  v.setMilliseconds(0);
  v.setSeconds(0);
  v.setMinutes(0);
  v.setHours(0);
  v.setDate(1);
  return v;
};

export const getNextMonthStart = (d) => {
  const v = getMonthStart(d);
  v.setMonth(v.getMonth() + 1);
  return v;
};

export const getFloat = (v, def = 0) => {
  return Number.isFinite(v) ? Number.parseFloat(v, 10) : def;
};

export const fromRange = (v, min = -Infinity, max = Infinity) => {
  const val = getFloat(v);
  return Math.max(min, Math.min(max, val));
};

export const textIncludes = (v1, v2) => {
  const t1 = getString(v1).toLowerCase();
  const t2 = getString(v2).toLowerCase();
  return t1.includes(t2);
};
