import { forwardRef } from 'react';

import * as icons from 'assets/icons';
import withProps from 'utils/withProps';
import { snakeCaseToCamelCase } from 'utils/helpers';
import IconBase from 'components/IconBase';

const createIconComponent = (src) => {
  return withProps(IconBase, {
    src,
    width: '1em',
    height: '1em',
  });
};

const IconComponents = {};

const Icon = forwardRef((props, ref) => {
  const { name, ...rest } = props;
  const TargetIcon = IconComponents[name];

  if (!name) {
    return <IconBase ref={ref} {...rest} />;
  }
  return !TargetIcon ? null : <TargetIcon {...rest} ref={ref} />;
});

Object.entries(icons).forEach(([name, path]) => {
  const ComponentName = snakeCaseToCamelCase(name, true);
  const Component = createIconComponent(path);
  IconComponents[name] = Component;
  Icon[ComponentName] = Component;
});

export default Icon;
