import { BrowserRouter } from 'react-router-dom';

import ProjectRoutes from 'routes/ProjectRoutes';

import ThemeProvider from './ThemeProvider';

const Providers = (props) => {
  const { children } = props;

  return (
    <ThemeProvider>
      <BrowserRouter>
        <ProjectRoutes>{children}</ProjectRoutes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Providers;
