import { forwardRef } from 'react';

import styled from 'utils/styled';
import ButtonBase from 'components/ButtonBase';

const sizes = {
  small: 32,
  medium: 40,
  large: 48,
};

const Root = styled(ButtonBase, {
  label: 'IconButton-root',
  ignore: ['hoverColor'],
})(({ theme, color, size, hoverColor }) => {
  const s = sizes[size] || size;
  const finalSize = Number.isFinite(s) ? s : sizes.medium;

  return {
    overflow: 'hidden',
    width: finalSize,
    height: finalSize,
    maxWidth: finalSize,
    maxHeight: finalSize,
    padding: theme.spacing(0),
    color: theme.sx({ color }).color,

    '&:hover': {
      color: theme.sx({ color: hoverColor }).color,
    },
  };
});

const IconButton = forwardRef((props, ref) => {
  const {
    color = 'primary.main',
    hoverColor = 'primary.dark',
    variant = 'text',
    size = 'medium',
    ...rest
  } = props;

  return (
    <Root
      ref={ref}
      variant={variant}
      color={color}
      size={size}
      hoverColor={hoverColor}
      {...rest}
    />
  );
});

export default IconButton;
