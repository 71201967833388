import {
  darken,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles';

import { isDevelopment } from 'constants/config';
import { textBase, textVariants } from './typography';

import * as colors from './colors';
import * as shadows from './shadows';
import './global.scss';

const {
  primary_light,
  primary_main,
  primary_dark,
  primary_contrast,
  secondary_light,
  secondary_main,
  secondary_dark,
  secondary_contrast,
  tertiary_light,
  tertiary_main,
  tertiary_dark,
  tertiary_contrast,
  success_light,
  success_main,
  success_dark,
  success_contrast,
  error_light,
  error_main,
  error_dark,
  error_contrast,
  warning_light,
  warning_main,
  warning_dark,
  warning_contrast,
  info_light,
  info_main,
  info_dark,
  info_contrast,
  white,
  grey0,
  grey100,
  grey200,
  grey300,
  grey400,
  grey500,
  grey600,
  grey700,
  grey800,
  grey900,
  background,
  vivid_red,
  electric_green,
  dark_blue,
  violet,
  divider,
  text_secondary,
} = colors;

export { colors };

const scrollWidth = 4;
const scroll_background = grey0;
const scroll_main = primary_dark;

const theme = extendTheme({
  cssVarPrefix: 'bw',
  colorSchemes: {
    light: {
      constants: {
        headerHeight: 60,
        sidebarWidth: 288,
      },
      palette: {
        colors,
        divider,
        primary: {
          light: primary_light,
          main: primary_main,
          dark: primary_dark,
          contrastText: primary_contrast,
        },
        secondary: {
          light: secondary_light,
          main: secondary_main,
          dark: secondary_dark,
          contrastText: secondary_contrast,
        },
        tertiary: {
          light: tertiary_light,
          main: tertiary_main,
          dark: tertiary_dark,
          contrastText: tertiary_contrast,
        },
        success: {
          light: success_light,
          main: success_main,
          dark: success_dark,
          contrastText: success_contrast,
        },
        error: {
          light: error_light,
          main: error_main,
          dark: error_dark,
          contrastText: error_contrast,
        },
        warning: {
          light: warning_light,
          main: warning_main,
          dark: warning_dark,
          contrastText: warning_contrast,
        },
        info: {
          light: info_light,
          main: info_main,
          dark: info_dark,
          contrastText: info_contrast,
        },
        special: {
          violet,
          vividRed: vivid_red,
          electricGreen: electric_green,
          darkBlue: dark_blue,
        },
        common: {
          white,
        },
        text: {
          secondary: text_secondary,
        },
        grey: {
          0: grey0,
          100: grey100,
          200: grey200,
          300: grey300,
          400: grey400,
          500: grey500,
          600: grey600,
          700: grey700,
          800: grey800,
          900: grey900,
        },
        background: {
          paper: background,
          default: background,
        },
      },
      shadow: {
        0: shadows.shadow0,
        100: shadows.shadow100,
        200: shadows.shadow200,
        300: shadows.shadow300,
      },
    },
    dark: {
      palette: {
        grey: {
          0: grey900,
          100: grey800,
          200: grey700,
          300: grey600,
          400: grey500,
          500: grey400,
          600: grey300,
          700: grey200,
          800: grey100,
          900: grey0,
        },
      },
    },
  },
  typography: {
    ...textBase,
    ...textVariants,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        *:focus-visible {
          outline-color: ${primary_dark};
          outline-offset: 2px;
        }

        *:not(body)::-webkit-scrollbar {
          width: ${scrollWidth}px;
          max-height: ${scrollWidth}px;
          border-radius: ${scrollWidth}px;
          background-color: ${scroll_background};
        }
        *:not(body)::-webkit-scrollbar:hover {
          background-color: ${darken(scroll_background, 0.1)};
        }
        *:not(body)::-webkit-scrollbar-track {
          border-radius: ${scrollWidth}px;
          background-color: ${scroll_background};
        }
        *:not(body)::-webkit-scrollbar-thumb {
          border-radius: ${scrollWidth}px;
          background-color: ${scroll_main};
        }
        *:not(body)::-webkit-scrollbar-thumb:hover {
          background-color: ${darken(scroll_main, 0.1)};
        }
      `,
    },
  },
});
theme.sx = theme.unstable_sx;

if (isDevelopment) {
  window.bw = {
    ...window.bw,
    theme,
  };
}

export default theme;
