import { useCallback } from 'react';
import { Stack, useColorScheme, useTheme } from '@mui/material';

import { logo, logo_text } from 'assets/images';
import { routes } from 'routes/routes';
import styled from 'utils/styled';
import useToggle from 'hooks/useToggle.hook';

import Ref from 'components/Ref';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import Badge from 'components/Badge';
import Button from 'components/Button';
import Text from 'components/Text';
import Switch from 'components/Switch';
import IconBase from 'components/IconBase';

import HeaderTabs from './HeaderTabs';
import styles from './headerLayout.module.scss';

const LogoWrapper = styled('div', {
  label: 'Logo-wrapper',
})(({ theme }) => ({
  display: 'inline-flex',
  position: 'relative',

  '&::before': {
    position: 'absolute',
    top: 1,
    left: 1,
    right: 1,
    bottom: 0,
    display: 'block',
    content: '""',
    zIndex: 0,
    backgroundColor: theme.vars.palette.common.white,
  },
}));

const Logo = styled('img', {
  label: 'Logo-image',
  props: {
    src: logo,
    alt: 'Baron Weather',
    width: 40,
    height: 34,
  },
})({ zIndex: 1 });

const HeaderLayout = () => {
  const [showBadge, toggleShowBadge] = useToggle();
  const { mode, setMode } = useColorScheme();

  const handleChangeMode = useCallback(() => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
  }, [mode, setMode]);

  const theme = useTheme();

  return (
    <div className={styles.root}>
      <div className={styles.logoWrapper}>
        <Ref nostyles className={styles.logo} to={routes.root.path()}>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <IconBase src={logo_text} color={theme.vars.palette.grey[900]} />
        </Ref>
      </div>

      <div className={styles.navigation}>
        <HeaderTabs />

        <div className={styles.account}>
          <Stack direction="row" spacing={1} alignItems="center" mr={3}>
            <Icon.Sun fontSize={20} color="grey.500" />
            <Switch
              size="xsmall"
              checked={mode === 'dark'}
              onChange={handleChangeMode}
            />
            <Icon.Moon fontSize={14} color="grey.500" />
          </Stack>

          <IconButton size="small" color="grey.500" onClick={toggleShowBadge}>
            <Badge show={showBadge}>
              <Icon.Bell />
            </Badge>
          </IconButton>

          <Button size="small" variant="text" padding={0.5}>
            <Text variant="inherit" color="grey.700">
              Barbara Roberts
            </Text>

            <Icon.Dots color="grey.500" sx={{ marginLeft: 1 }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeaderLayout;
