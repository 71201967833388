import { forwardRef, useCallback } from 'react';
import { Tab, Tabs } from '@mui/material';

import { getArray, getFunc } from 'utils/helpers';
import Ref from 'components/Ref';

const refProps = {
  nostyles: true,
};

const TabsBase = forwardRef((props, ref) => {
  const {
    value,
    options,
    onChange,
    onValue,
    TabComponent = Tab,
    TabsComponent = Tabs,
    ...rest
  } = props;

  const handleChange = useCallback(
    (e, v) => {
      getFunc(onChange)(e, v);
      getFunc(onValue)(v);
    },
    [onChange, onValue]
  );

  return (
    <TabsComponent ref={ref} value={value} onChange={handleChange} {...rest}>
      {getArray(options).map((opt) => {
        const {
          to,
          value: tabValue,
          component: tabComponent,
          ...optRest
        } = opt;

        const component = tabComponent || (to ? Ref : undefined);
        const additionalProps = to ? refProps : undefined;

        return (
          <TabComponent
            to={to}
            key={tabValue}
            value={tabValue}
            component={component}
            {...additionalProps}
            {...optRest}
          />
        );
      })}
    </TabsComponent>
  );
});

export default TabsBase;
