export const primary_light = '#50B2DF';
export const primary_main = '#0496D9';
export const primary_dark = '#09638C';
export const primary_contrast = '#D8F3FF';

export const secondary_light = '#9AABBB';
export const secondary_main = '#7F8F9F';
export const secondary_dark = '#708293';
export const secondary_contrast = '#F3F9FF';

export const tertiary_light = '#921221';
export const tertiary_main = '#DA1A32';
export const tertiary_dark = '#E81830';
export const tertiary_contrast = '#510D15';

export const success_light = '#5CD8B8';
export const success_main = '#16C79A';
export const success_dark = '#0F8B6C';
export const success_contrast = '#E8F9F5';

export const error_light = '#F58787';
export const error_main = '#F05454';
export const error_dark = '#A83B3B';
export const error_contrast = '#FEEEEE';

export const warning_light = '#FFF3E8';
export const warning_main = '#FA963A';
export const warning_dark = '#E86F00';
export const warning_contrast = '#FFF9EE';

export const info_light = '#689DFF';
export const info_main = '#2773FF';
export const info_dark = '#1B51B3';
export const info_contrast = '#F8FAFF';

export const text_secondary = '#728095';

export const white = '#FFFFFF';

export const grey0 = white;
export const grey100 = '#F0F2F5';
export const grey200 = '#AFB8C0';
export const grey300 = '#94A3B2';
export const grey400 = '#7F8F9F';
export const grey500 = '#677A8E';
export const grey600 = '#5A6672';
export const grey700 = '#48515B';
export const grey800 = '#333C47';
export const grey900 = '#171E27';

export const divider = '#DBE0EA';
export const background = '#FAFBFE';

export const input_resting = '#F4F5F8';
export const control_resting = '#E3E7EB';

export const vivid_red = '#E64610';
export const electric_green = '#92DA1A';
export const dark_blue = '#0D2B54';
export const violet = '#6D008C';
