import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { routes } from 'routes/routes';
import Tabs from 'components/Tabs';

const HeaderTabs = () => {
  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    const [tab] = pathname.split('/').filter(Boolean);
    return tab || 'timeline';
  }, [pathname]);

  const tabs = useMemo(() => {
    return [
      {
        value: 'timeline',
        label: 'Timeline',
        to: routes.timeline.path(),
      },
      {
        value: 'alerts',
        label: 'Alerts',
        to: routes.alerts.path(),
      },
      {
        value: 'map',
        label: 'Map',
        to: routes.map.path(),
      },
      {
        value: 'reports',
        label: 'Reports',
        to: routes.reports.path(),
      },
    ];
  }, []);

  return (
    <Tabs gap={2} bgcolor="transparent" value={currentTab} options={tabs} />
  );
};

export default HeaderTabs;
